/* tslint:disable */
/* eslint-disable */
/**
 * Customer API
 * API for Scope Customers
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@get-scope.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface CreateCustomer201Response
 */
export interface CreateCustomer201Response {
    /**
     * 
     * @type {number}
     * @memberof CreateCustomer201Response
     */
    'customerId'?: number;
}
/**
 * 
 * @export
 * @interface CreateCustomerImport201Response
 */
export interface CreateCustomerImport201Response {
    /**
     * 
     * @type {number}
     * @memberof CreateCustomerImport201Response
     */
    'customerId'?: number;
}
/**
 * 
 * @export
 * @interface CreateCustomerImportRequest
 */
export interface CreateCustomerImportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerImportRequest
     */
    'tenantId'?: string;
    /**
     * 
     * @type {WebhookNotification}
     * @memberof CreateCustomerImportRequest
     */
    'notification'?: WebhookNotification;
    /**
     * 
     * @type {CustomerDetails}
     * @memberof CreateCustomerImportRequest
     */
    'debtor'?: CustomerDetails;
}
/**
 * Customer details
 * @export
 * @interface CustomerDetails
 */
export interface CustomerDetails {
    /**
     * 
     * @type {number}
     * @memberof CustomerDetails
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'customerType'?: CustomerDetailsCustomerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'businessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'postalcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'numberSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'country'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetails
     */
    'lat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetails
     */
    'lon'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'emailPreference'?: CustomerDetailsEmailPreferenceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDetails
     */
    'emailUserJourney'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'language'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDetails
     */
    'autoPush'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDetails
     */
    'includeVAT'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDetails
     */
    'reverseChargeVAT'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'instagramUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'extCustomerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    'source'?: CustomerDetailsSourceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDetails
     */
    'alternativeInvoice'?: boolean;
    /**
     * 
     * @type {CustomerDetailsAltInvoiceData}
     * @memberof CustomerDetails
     */
    'altInvoiceData'?: CustomerDetailsAltInvoiceData;
}

export const CustomerDetailsCustomerTypeEnum = {
    P: 'P',
    C: 'C',
    I: 'I'
} as const;

export type CustomerDetailsCustomerTypeEnum = typeof CustomerDetailsCustomerTypeEnum[keyof typeof CustomerDetailsCustomerTypeEnum];
export const CustomerDetailsEmailPreferenceEnum = {
    None: 'none',
    EachStep: 'each-step',
    ReadyForTransport: 'ready-for-transport'
} as const;

export type CustomerDetailsEmailPreferenceEnum = typeof CustomerDetailsEmailPreferenceEnum[keyof typeof CustomerDetailsEmailPreferenceEnum];
export const CustomerDetailsSourceEnum = {
    Internal: 'Internal',
    External: 'External'
} as const;

export type CustomerDetailsSourceEnum = typeof CustomerDetailsSourceEnum[keyof typeof CustomerDetailsSourceEnum];

/**
 * 
 * @export
 * @interface CustomerDetailsAltInvoiceData
 */
export interface CustomerDetailsAltInvoiceData {
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsAltInvoiceData
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsAltInvoiceData
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsAltInvoiceData
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsAltInvoiceData
     */
    'postalcode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsAltInvoiceData
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsAltInvoiceData
     */
    'numberSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsAltInvoiceData
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsAltInvoiceData
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsAltInvoiceData
     */
    'country'?: string | null;
}
/**
 * Customer settings
 * @export
 * @interface CustomerSettings
 */
export interface CustomerSettings {
    /**
     * 
     * @type {string}
     * @memberof CustomerSettings
     */
    'emailPreference'?: CustomerSettingsEmailPreferenceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSettings
     */
    'emailUserJourney'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerSettings
     */
    'language'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSettings
     */
    'autoPush'?: boolean;
}

export const CustomerSettingsEmailPreferenceEnum = {
    None: 'none',
    EachStep: 'each-step',
    ReadyForTransport: 'ready-for-transport'
} as const;

export type CustomerSettingsEmailPreferenceEnum = typeof CustomerSettingsEmailPreferenceEnum[keyof typeof CustomerSettingsEmailPreferenceEnum];

/**
 * 
 * @export
 * @interface CustomerSummary
 */
export interface CustomerSummary {
    /**
     * 
     * @type {number}
     * @memberof CustomerSummary
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummary
     */
    'customerType'?: CustomerSummaryCustomerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummary
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummary
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummary
     */
    'businessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummary
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummary
     */
    'userName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerSummary
     */
    'lat'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSummary
     */
    'lon'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSummary
     */
    'includeVAT'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummary
     */
    'extCustomerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummary
     */
    'source'?: CustomerSummarySourceEnum;
}

export const CustomerSummaryCustomerTypeEnum = {
    P: 'P',
    C: 'C',
    I: 'I'
} as const;

export type CustomerSummaryCustomerTypeEnum = typeof CustomerSummaryCustomerTypeEnum[keyof typeof CustomerSummaryCustomerTypeEnum];
export const CustomerSummarySourceEnum = {
    Internal: 'Internal',
    External: 'External'
} as const;

export type CustomerSummarySourceEnum = typeof CustomerSummarySourceEnum[keyof typeof CustomerSummarySourceEnum];

/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'error'?: string;
}
/**
 * Health report
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    'service'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isAlive'?: boolean;
}
/**
 * 
 * @export
 * @interface PutInstagramRequest
 */
export interface PutInstagramRequest {
    /**
     * 
     * @type {string}
     * @memberof PutInstagramRequest
     */
    'instagram'?: string;
}
/**
 * 
 * @export
 * @interface WebhookNotification
 */
export interface WebhookNotification {
    /**
     * 
     * @type {WebhookNotificationContent}
     * @memberof WebhookNotification
     */
    'Content'?: WebhookNotificationContent;
    /**
     * 
     * @type {string}
     * @memberof WebhookNotification
     */
    'HashCode'?: string;
}
/**
 * 
 * @export
 * @interface WebhookNotificationContent
 */
export interface WebhookNotificationContent {
    /**
     * 
     * @type {string}
     * @memberof WebhookNotificationContent
     */
    'Topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookNotificationContent
     */
    'ClientId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WebhookNotificationContent
     */
    'Division'?: number;
    /**
     * 
     * @type {string}
     * @memberof WebhookNotificationContent
     */
    'Action'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookNotificationContent
     */
    'Key'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookNotificationContent
     */
    'ExactOnlineEndpoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookNotificationContent
     */
    'EventCreatedOn'?: string;
}

/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new customer on the system
         * @summary Create new customer
         * @param {CustomerDetails} [customerDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (customerDetails?: CustomerDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateCustomerImportRequest} [createCustomerImportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerImport: async (createCustomerImportRequest?: CreateCustomerImportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/customerImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes specified customer
         * @summary Delete customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomerById', 'id', id)
            const localVarPath = `/customers/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSync: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/administration/force-sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSyncExt: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/administration/force-sync-ext`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves customer details for the given customer Id (within tenant context)
         * @summary Get customer details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerById', 'id', id)
            const localVarPath = `/customers/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves customer details for the given EXTERNAL customer Id (within tenant context)
         * @summary Get customer details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerExternalById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerExternalById', 'id', id)
            const localVarPath = `/customers/customer/external/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves customer settings for the given customer Id (within tenant context)
         * @summary Get customer settings details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSettingsById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerSettingsById', 'id', id)
            const localVarPath = `/customers/customer/{id}/settings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of customers from the system\\n\\nReturns only the customers that are available for the logged-in tenant
         * @summary Fetch customer list
         * @param {string} [nameFilter] Filter by name / business name
         * @param {Array<number>} [orderIds] Filter by orderIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (nameFilter?: string, orderIds?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nameFilter !== undefined) {
                localVarQueryParameter['nameFilter'] = nameFilter;
            }

            if (orderIds) {
                localVarQueryParameter['orderIds'] = orderIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update customer
         * @summary Update customer
         * @param {CustomerDetails} [customerDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomer: async (customerDetails?: CustomerDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates customer settings for the given customer Id (within tenant context)
         * @summary Update customer settings
         * @param {string} id 
         * @param {CustomerSettings} [customerSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerSettingsById: async (id: string, customerSettings?: CustomerSettings, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCustomerSettingsById', 'id', id)
            const localVarPath = `/customers/customer/{id}/settings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the instagram of a customer in the system (customer call only)
         * @summary Update customer Instagram
         * @param {PutInstagramRequest} [putInstagramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInstagram: async (putInstagramRequest?: PutInstagramRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/customer/instagram`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putInstagramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new customer on the system
         * @summary Create new customer
         * @param {CustomerDetails} [customerDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(customerDetails?: CustomerDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCustomer201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(customerDetails, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.createCustomer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 
         * @param {CreateCustomerImportRequest} [createCustomerImportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerImport(createCustomerImportRequest?: CreateCustomerImportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCustomerImport201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerImport(createCustomerImportRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.createCustomerImport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Deletes specified customer
         * @summary Delete customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.deleteCustomerById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceSync(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceSync(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.forceSync']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceSyncExt(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceSyncExt(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.forceSyncExt']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves customer details for the given customer Id (within tenant context)
         * @summary Get customer details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.getCustomerById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves customer details for the given EXTERNAL customer Id (within tenant context)
         * @summary Get customer details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerExternalById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerExternalById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.getCustomerExternalById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves customer settings for the given customer Id (within tenant context)
         * @summary Get customer settings details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSettingsById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSettingsById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.getCustomerSettingsById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetch a list of customers from the system\\n\\nReturns only the customers that are available for the logged-in tenant
         * @summary Fetch customer list
         * @param {string} [nameFilter] Filter by name / business name
         * @param {Array<number>} [orderIds] Filter by orderIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(nameFilter?: string, orderIds?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(nameFilter, orderIds, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.getCustomers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update customer
         * @summary Update customer
         * @param {CustomerDetails} [customerDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomer(customerDetails?: CustomerDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomer(customerDetails, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.putCustomer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates customer settings for the given customer Id (within tenant context)
         * @summary Update customer settings
         * @param {string} id 
         * @param {CustomerSettings} [customerSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomerSettingsById(id: string, customerSettings?: CustomerSettings, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomerSettingsById(id, customerSettings, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.putCustomerSettingsById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update the instagram of a customer in the system (customer call only)
         * @summary Update customer Instagram
         * @param {PutInstagramRequest} [putInstagramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putInstagram(putInstagramRequest?: PutInstagramRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putInstagram(putInstagramRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.putInstagram']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * Create a new customer on the system
         * @summary Create new customer
         * @param {CustomerDetails} [customerDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(customerDetails?: CustomerDetails, options?: any): AxiosPromise<CreateCustomer201Response> {
            return localVarFp.createCustomer(customerDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateCustomerImportRequest} [createCustomerImportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerImport(createCustomerImportRequest?: CreateCustomerImportRequest, options?: any): AxiosPromise<CreateCustomerImport201Response> {
            return localVarFp.createCustomerImport(createCustomerImportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes specified customer
         * @summary Delete customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomerById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSync(options?: any): AxiosPromise<void> {
            return localVarFp.forceSync(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSyncExt(options?: any): AxiosPromise<void> {
            return localVarFp.forceSyncExt(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves customer details for the given customer Id (within tenant context)
         * @summary Get customer details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById(id: string, options?: any): AxiosPromise<CustomerDetails> {
            return localVarFp.getCustomerById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves customer details for the given EXTERNAL customer Id (within tenant context)
         * @summary Get customer details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerExternalById(id: string, options?: any): AxiosPromise<CustomerDetails> {
            return localVarFp.getCustomerExternalById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves customer settings for the given customer Id (within tenant context)
         * @summary Get customer settings details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSettingsById(id: string, options?: any): AxiosPromise<CustomerSettings> {
            return localVarFp.getCustomerSettingsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of customers from the system\\n\\nReturns only the customers that are available for the logged-in tenant
         * @summary Fetch customer list
         * @param {string} [nameFilter] Filter by name / business name
         * @param {Array<number>} [orderIds] Filter by orderIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(nameFilter?: string, orderIds?: Array<number>, options?: any): AxiosPromise<Array<CustomerSummary>> {
            return localVarFp.getCustomers(nameFilter, orderIds, options).then((request) => request(axios, basePath));
        },
        /**
         * update customer
         * @summary Update customer
         * @param {CustomerDetails} [customerDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomer(customerDetails?: CustomerDetails, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomer(customerDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates customer settings for the given customer Id (within tenant context)
         * @summary Update customer settings
         * @param {string} id 
         * @param {CustomerSettings} [customerSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerSettingsById(id: string, customerSettings?: CustomerSettings, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomerSettingsById(id, customerSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the instagram of a customer in the system (customer call only)
         * @summary Update customer Instagram
         * @param {PutInstagramRequest} [putInstagramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInstagram(putInstagramRequest?: PutInstagramRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putInstagram(putInstagramRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * Create a new customer on the system
     * @summary Create new customer
     * @param {CustomerDetails} [customerDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createCustomer(customerDetails?: CustomerDetails, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createCustomer(customerDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateCustomerImportRequest} [createCustomerImportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createCustomerImport(createCustomerImportRequest?: CreateCustomerImportRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createCustomerImport(createCustomerImportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes specified customer
     * @summary Delete customer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteCustomerById(id: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteCustomerById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public forceSync(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).forceSync(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public forceSyncExt(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).forceSyncExt(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves customer details for the given customer Id (within tenant context)
     * @summary Get customer details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerById(id: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves customer details for the given EXTERNAL customer Id (within tenant context)
     * @summary Get customer details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerExternalById(id: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerExternalById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves customer settings for the given customer Id (within tenant context)
     * @summary Get customer settings details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerSettingsById(id: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerSettingsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of customers from the system\\n\\nReturns only the customers that are available for the logged-in tenant
     * @summary Fetch customer list
     * @param {string} [nameFilter] Filter by name / business name
     * @param {Array<number>} [orderIds] Filter by orderIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomers(nameFilter?: string, orderIds?: Array<number>, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomers(nameFilter, orderIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update customer
     * @summary Update customer
     * @param {CustomerDetails} [customerDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putCustomer(customerDetails?: CustomerDetails, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putCustomer(customerDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates customer settings for the given customer Id (within tenant context)
     * @summary Update customer settings
     * @param {string} id 
     * @param {CustomerSettings} [customerSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putCustomerSettingsById(id: string, customerSettings?: CustomerSettings, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putCustomerSettingsById(id, customerSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the instagram of a customer in the system (customer call only)
     * @summary Update customer Instagram
     * @param {PutInstagramRequest} [putInstagramRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putInstagram(putInstagramRequest?: PutInstagramRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putInstagram(putInstagramRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get service health
         * @summary GET endpoint for health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * Get service health
         * @summary GET endpoint for health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HealthApi.getHealth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * Get service health
         * @summary GET endpoint for health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * Get service health
     * @summary GET endpoint for health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public getHealth(options?: RawAxiosRequestConfig) {
        return HealthApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }
}



